.quill.custom-quill {
  border-radius: 6px;
}

#toolbar {
  border-radius: 6px 6px 0px 0px;
}

.ql-toolbar.custom-quill-dark .ql-stroke {
  fill: none;
  stroke: #fff;
}

.ql-toolbar.custom-quill-dark .ql-fill {
  fill: #fff;
  stroke: none;
}
.ql-toolbar.custom-quill-dark button {
  color: #fff;
}

.ql-toolbar.custom-quill-dark .ql-picker {
  color: #fff;
}
.ql-toolbar.custom-quill-dark  {
  color: #fff;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: rgba(210, 214, 218);
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}


.quill.custom-quill .ql-editor {
  min-height: 150px;
  max-height: 500px;
  color: #bcbcbc;
  overflow: auto;
}

.quill.custom-quill .ql-snow .ql-picker {
  color: #bcbcbc;
}

.quill.custom-quill .ql-snow .ql-stroke {
  stroke: #bcbcbc;
}
.ql-toolbar.custom-quill-dark .ql-picker-options {
  background: #323a54;
}

.quill.custom-quill-dark>.ql-container>.ql-editor.ql-blank::before {
  color: white;
}
