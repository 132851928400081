@keyframes rotate {
    to {
        transform: rotate(1turn);
    }
}

.display-content > * {
    max-width: 100%;
}
.display-content img {
    max-width: 100%;
}

.react-datepicker-wrapper:has(.custom-daypicker) {
    width: 100%;
}
